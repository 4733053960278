import { Splide } from '@splidejs/splide';
import { getElement } from '@kogoshvili/facade/client/utils';
class NavBar {
  script_test() {
    console.log('Navbar Script');
  }
  async script() {
    const splide: any = new Splide('.splide', {
      perPage: 6,
      arrows: false,
      pagination: false,
      padding: {
        right: '3rem',
        left: '3rem'
      }
    });
    splide.mount();
    this.script_test();
    const w = await this.serverTest();
    console.log('response', w);
    const element = getElement(this);
    document.addEventListener("mousedown", event => {
      if (element && !element.contains((event.target as any))) {
        if (this.showCart()) {
          this.handleClick(false);
        }
      }
    });
  }
}
export default NavBar;